import React from 'react'
import './Standard.css'

function Partners() {
  return (
    <div className='standard-box'>

      <div className='partners-header'>
      <p>EVENT PARTNERS</p>
      </div>
    <div className='standard-container'>
      <p>Firstly, thank you for supporting the Arizona VEX community by hosting an event!</p>
      <br></br>
      <p>
        18x18az offers many services, specializing in Tournament Manager, audiovisual, and livestream operation.
        Our prices start at $200, and a summary of our services can be found below.
        Download the latest release of our <a href='https://github.com/18x18az/technical-rider/releases/latest' target='_blank' rel="noreferrer">technical rider</a> for complete details
        and email us at <a href='mailto:contact@18x18az.org'>contact@18x18az.org</a> for inquries and questions.
      </p>
      <h2>Field Control and Audiovisual Equipment</h2>
      <p>
        As part of our base package we will operate and bring all equipment necesssary to run a quality event, such as:
      </p>
        <ul>
          <li>Field Control electronics for two competition fields and one skills field</li>
          <li>Complete audiovisual package</li>
          <li>Referee scoring tablets</li>
          <li>Streaming equipment</li>
        </ul>
        <p>For an additional $100 fee we can support field control for a third competition field and a second skills field.</p>
      <h2>Pipe and Drape</h2>
      <p>Pipe and Drape is immensely useful for partioning a large venue into two spaces.
        18x18az owns a small amount of pipe and drape (roughly 70 feet) and can provide it for an additional $150 fee.
      </p>
    </div>
    </div>
  )
}

export default Partners